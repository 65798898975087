<template>
  <div>
    <v-form v-model="valid" @submit.prevent="submitHandler" ref="addDegreeForm">
      <div class="d-flex align-center justify-space-between">
        <span
          class="d-block font-700"
          :class="isMobile ? 'font-18' : 'font-24 '"
          >{{
            isEdit ? $t("forms.editDegree") : $t("dashboard.addNewDegree")
          }}</span
        >
        <dialog-btns
          v-if="isMobile"
          :topBtns="true"
          :btnTitle="$t('forms.save')"
          :closeTitle="$t('forms.discard')"
          @close="$emit('close')"
        ></dialog-btns>
        <img
          v-if="!isMd"
          class="cursor-pointer"
          @click="$emit('close')"
          :src="require('@/assets/images/dashboard/icons/close-black.svg')"
        />
      </div>
      <v-row class="mt-2">
        <!-- Degree -->
        <v-col cols="12">
          <base-auto-complete
            v-model="obj.degree_id"
            :isRequired="true"
            :title="$t('forms.degree')"
            :placeholder="$t('forms.degree')"
            :items="data.degrees"
          ></base-auto-complete>
        </v-col>
        <!-- University or Institution country -->
        <v-col cols="12">
          <base-input
            :isRequired="true"
            v-model="obj.university_or_institution"
            :title="$t('forms.universityOrInstitutionCountry')"
            :placeholder="$t('forms.universityOrInstitutionCountry')"
          ></base-input>
        </v-col>        
        <!-- Country -->
        <v-col cols="12">
          <base-auto-complete
            v-model="obj.university_or_institution_country_id"
            :isRequired="true"
            :title="$t('forms.country')"
            :placeholder="$t('forms.country')"
            :items="data.university_or_institution_country"
          ></base-auto-complete>
        </v-col>


        <!-- Field of study major -->
        <v-col cols="12">
          <base-auto-complete
            :isRequired="true"
            :title="$t('forms.fieldOfStudyMajor')"
            :placeholder="$t('forms.fieldOfStudyMajor')"
            :items="data.field_of_study_major"
            v-model="obj.field_of_study_major_id"
          ></base-auto-complete>
        </v-col>

        <!-- Graduation Date from * -->
        <v-col cols="12" md="6">
          <div class="mt-3">
            <span class="darktext--text font-16 font-600 d-block mb-2"
              >{{ $t("forms.graduationDateFrom") }}

              <span class="error--text">*</span>
            </span>
            <flat-pickr
              placeholder="yyyy-mm-dd"
              v-model="obj.graduation_date_from"
              :config="{
                maxDate: 'today',
              }"              
            ></flat-pickr>
          </div>
        </v-col>

        <!-- Graduation Date to * -->
        <v-col cols="12" md="6">
          <div class="mt-3">
            <span class="darktext--text font-16 font-600 d-block mb-2"
              >{{ $t("forms.graduationDateTo") }}

              <span class="error--text">*</span>
            </span>
            <flat-pickr
              placeholder="yyyy-mm-dd"
              v-model="obj.graduation_date_to"
              :config="{
                maxDate: 'today',
                minDate: obj.graduation_date_from
              }"
            ></flat-pickr>
          </div>
        </v-col>
      </v-row>
      <dialog-btns
        v-if="!isMobile"
        :btnTitle="isEdit ? $t('forms.save') : $t('forms.add')"
        :closeTitle="isEdit ? $t('forms.discard') : $t('forms.cancel')"
        @close="$emit('close')"
        :isReverse="true"
      ></dialog-btns>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogBtns from "./DialogBtns.vue";
import BaseAutoComplete from "../BaseAutoComplete.vue";

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    editItem: {
      type: Object,
      default: () => {},
    },
  },

  components: { DialogBtns , BaseAutoComplete},
  data: () => ({
    obj: {},
    date: "",
    valid: false,
  }),
  computed: {
    ...mapGetters(["countries"]),
  },
  methods: {
    dateHandler(event, type) {
      this.obj[type] = event;
    },
    async submitHandler() {
      let valid = this.$refs.addDegreeForm.validate();
      if (!valid || !this.obj.graduation_date_from || !this.obj.graduation_date_to) {
        setTimeout(() => {
          document
            .querySelector(".v-input.error--text:first-of-type")
            .scrollIntoView({
              behavior: "smooth",
            });
        }, 100);

        this.$store.dispatch("showSnack", {
          text: this.$t("rules.checkAllFields"),
          color: "error",
        });
        return;
      }
      let formData = new FormData();
      formData.append("degree_id", this.obj.degree_id);
      formData.append(
        "university_or_institution",
        this.obj.university_or_institution
      );
      formData.append(
        "university_or_institution_country_id",
        this.obj.university_or_institution_country_id
      );
      formData.append("graduation_date_from", this.obj.graduation_date_from);
      formData.append("graduation_date_to", this.obj.graduation_date_to);
      formData.append(
        "field_of_study_major_id",
        this.obj.field_of_study_major_id
      );

      let url= "degree/add";
      if (this.isEdit) {
        url = "degree/update";
        formData.append(
        "doctor_degree_id",
        this.obj.id
      );
      }
       

      let { data } = await this.$axios.post(url, formData);
      if (data.success) {
        this.$store.dispatch("showSnack", {
          text: "Added Successfully",
          color: "success",
        });
        this.$emit("updateData");
      } else {
        this.$store.dispatch("showSnack", {
          text: "Something went wrong",
          color: "error",
        });
      }
    },
  },
  created() {
    if (this.isEdit) {
      this.obj = { ...this.editItem };
      this.obj.graduation_date_from = this.editItem.graduation_date_from.slice(
        0,
        10
      );
      this.obj.graduation_date_to = this.editItem.graduation_date_to.slice(
        0,
        10
      );
      this.data.degrees.forEach((degree) => {
        if (degree.name == this.editItem.degree) {
          this.obj.degree_id = degree.id;
        }
      });
      this.data.university_or_institution_country.forEach((country) => {
        if (country.name == this.editItem.country) {
          this.obj.university_or_institution_country_id = country.id;
        }
      });
    }
  },
};
</script>

<style></style>
