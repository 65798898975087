<template>
  <div class="dashboard-documnets white radius-15 pa-2 pb-4">
    <profile-head
      :title="$t('dashboard.degrees')"
      :btnTitle="$t('dashboard.addNewDegree')"
      @click="openHandler('new')"
      :icon="require('@/assets/images/dashboard/icons/plus.svg')"
    ></profile-head>

    <div
      class="mt-4 bluebgdash"
      :class="isMobile ? 'pa-3' : !isComplete ? ' py-6 px-9' : 'pa-3 '"
      v-for="(degree, i) in data"
      :key="i"
    >
      <!-- mobile btns -->
      <div class="text-right" v-if="isMobile">
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              :src="
                require('@/assets/images/dashboard/icons/more-vertical.svg')
              "
              alt=""
            />
          </template>
          <v-list>
            <div class="d-flex align-center px-4 py-1 pr-9 my-1">
              <img
                class="mx-3"
                :src="
                  require('@/assets/images/dashboard/icons/minus-circle.svg')
                "
                @click="deleteHandler(degree.id)"
              />
              <span class="error--text font-16 font-700">{{
                $t("forms.delete")
              }}</span>
            </div>

            <!-- edit btn -->
            <div
              class="d-flex align-center px-4 py-1 pr-9 my-1"
              @click="editHandler(degree)"
            >
              <img
                class="mx-3"
                :src="require('@/assets/images/dashboard/icons/edit.svg')"
              />
              <span class="darkprimary--text font-16 font-700">{{
                $t("forms.edit")
              }}</span>
            </div>
          </v-list>
        </v-menu>
      </div>

      <!-- desktop btns -->
      <div class="d-flex align-center justify-space-between" v-if="!isMobile">
        <base-info :title="$t('forms.degree')" :value="degree.degree">
        </base-info>
        <!-- desktop btns -->
        <div class="d-flex align-center">
          <!-- delete btn -->
          <div
            class="cursor-pointer d-flex align-center mr-7"
            @click="deleteHandler(degree.id)"
          >
            <img
              class="mx-3"
              :src="require('@/assets/images/dashboard/icons/minus-circle.svg')"
            />
            <span class="error--text font-16 font-700">{{
              $t("forms.delete")
            }}</span>
          </div>

          <!-- edit btn -->
          <div
            class="cursor-pointer d-flex align-center mr-7"
            @click="editHandler(degree)"
          >
            <img
              class="mx-3"
              :src="require('@/assets/images/dashboard/icons/edit.svg')"
            />
            <span class="darkprimary--text font-16 font-700">{{
              $t("forms.edit")
            }}</span>
          </div>
        </div>
      </div>

      <v-row class="mt-6">
        <v-col v-if="isMobile" :cols="12">
          <base-info
            :title="$t('forms.degree')"
            :value="degree.degree"
          ></base-info>
        </v-col>
        <!-- country -->
        <v-col :lg="2" :md="3" :cols="12">
          <base-info
            :title="$t('forms.country')"
            :value="degree.country"
          ></base-info>
        </v-col>

        <!-- University or Institution country -->
        <v-col :lg="!isComplete ? 3 : 4" :md="isComplete ? 3 : 4" :cols="12">
          <base-info
            :title="$t('forms.universityOrInstitutionCountry')"
            :value="degree.university_or_institution"
          ></base-info>
        </v-col>

        <!-- Field of study major -->
        <v-col :lg="!isComplete ? 3 : 4" :md="isComplete ? 3 : 4" :cols="12">
          <base-info
            :title="$t('forms.fieldOfStudyMajor')"
            :value="degree.field_of_study_major_name"
          ></base-info>
        </v-col>

        <!-- Graduation date From -->
        <v-col :lg="!isComplete ? 2 : 4" :md="isComplete ? 3 : 4" :cols="12">
          <base-info
            :title="$t('forms.graduationDateFrom')"
            :value="degree.graduation_date_from"
          ></base-info>
        </v-col>

        <!-- Graduation date To -->
        <v-col :lg="!isComplete ? 2 : 4" :md="isComplete ? 3 : 4" :cols="12">
          <base-info
            :title="$t('forms.graduationDateTo')"
            :value="degree.graduation_date_to"
          ></base-info>
        </v-col>
      </v-row>
    </div>

    <v-dialog
      v-model="degreeDialog"
      :content-class="isMobile ? 'pa-4 radius-5 white' : 'pa-6 white radius-16'"
      max-width="575px"
    >
      <add-degree
        @updateData="getUpdated"
        @close="degreeDialog = false"
        :data="dataList"
        :isEdit="isEdit"
        :editItem="editItem"
        :key="degreeDialog"
      ></add-degree>
    </v-dialog>

    <!-- delete dialog -->
    <v-dialog v-model="deleteDialog" max-width="400" content-class="white">
      <delete-item
        @close="deleteDialog = false"
        @ok="okHandler"
        url="degree/delete"
        :obj="selectedDeleteObj"
      ></delete-item>
    </v-dialog>
  </div>
</template>

<script>
import DeleteItem from "../../../../../components/core/DeleteItem.vue";
import BaseInfo from "../../../components/BaseInfo.vue";
import AddDegree from "../../../components/dialogs/AddDegree.vue";
import ProfileHead from "../../../components/ProfileHead.vue";
export default {
  props: {
    isComplete: {
      type: Boolean,
      default: false,
    },
  },
  components: { ProfileHead, BaseInfo, AddDegree, DeleteItem },
  data: () => ({
    deleteDialog: false,
    data: [],
    degreeDialog: false,
    isEdit: false,
    dataList: {},
    editItem: {},
    selectedDeleteObj: {},
  }),
  methods: {
    openHandler(type) {
      this.degreeDialog = true;
      if (type == "new") {
        this.isEdit = false;
      } else {
        this.isEdit = true;
      }
    },
    deleteHandler(id) {
      this.selectedDeleteObj = { key: "degree_id", id };
      this.deleteDialog = true;
    },
    editHandler(item) {
      this.isEdit = true;
      this.degreeDialog = true;
      this.editItem = item;
    },
    async okHandler() {
      this.deleteDialog = false;
      await this.getData();
    },
    async getData() {
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "degrees";
      }
      let { data } = await this.$axios.get(url);
      this.data = data.data.degrees;
      if(this.data.length > 0){
        this.$store.dispatch('profileDegrees', true)
      }
    },
    async getDataList() {
      let url;
      if (localStorage.getItem("role") == "doctor") {
        url = "degrees/information";
      }
      let { data } = await this.$axios.get(url);
      this.dataList = data.data;
    },
    async getUpdated() {
      this.degreeDialog = false;
      await this.getData();
    },
  },
  async created() {
    await this.getData();
    await this.getDataList();
  },
};
</script>

<style></style>
